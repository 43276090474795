<template>
  <div :class="['ele-exception ele-text-center',{'ele-exception-dark':$store.state.theme.theme==='dark'}]">
    <div class="ele-exception-img">
      <svg width="227" height="269" viewBox="0 0 227 269" xmlns="http://www.w3.org/2000/svg"
           xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
          <path
              d="M0 131.5c0-29.712 2.002-61.194 6.007-94.445 2.225-18.473 17.225-32.78 35.782-34.13C68.622.976 91.041 0 109.044 0c18.09 0 40.799.985 68.129 2.954 18.572 1.338 33.589 15.656 35.809 34.144C216.994 70.51 219 102.083 219 131.813c0 29.66-1.997 61.03-5.99 94.114-2.23 18.474-17.237 32.778-35.797 34.12-27.238 1.969-49.735 2.953-67.491 2.953-17.794 0-40.43-.989-67.91-2.966-18.569-1.336-33.585-15.649-35.81-34.133C2 192.666 0 161.2 0 131.5z"
              id="b"/>
          <filter x="-4.5%" y="-1.4%" width="106.2%" height="104.4%" filterUnits="objectBoundingBox" id="a">
            <feMorphology radius=".81" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"/>
            <feOffset dx="-6" dy="4" in="shadowSpreadOuter1" result="shadowOffsetOuter1"/>
            <feComposite in="shadowOffsetOuter1" in2="SourceAlpha" operator="out" result="shadowOffsetOuter1"/>
            <feColorMatrix values="0 0 0 0 0.639215686 0 0 0 0 0.694117647 0 0 0 0 0.749019608 0 0 0 0.3 0"
                           in="shadowOffsetOuter1"/>
          </filter>
          <path
              d="M39.654 130.992c0-20.899.881-41.798 2.643-62.697.97-11.504 9.9-20.738 21.366-22.091a388.15 388.15 0 0 1 45.531-2.687c15.167 0 30.333.895 45.5 2.684 11.47 1.353 20.403 10.593 21.368 22.103a755.378 755.378 0 0 1 2.657 63.124c0 20.88-.88 41.76-2.64 62.638-.97 11.505-9.903 20.74-21.369 22.091a389.034 389.034 0 0 1-45.598 2.693c-15.145 0-30.291-.894-45.437-2.682-11.468-1.355-20.4-10.593-21.365-22.101a754.09 754.09 0 0 1-2.656-63.075z"
              id="d"/>
          <filter x="-6%" y="-2.3%" width="108.6%" height="105.9%" filterUnits="objectBoundingBox" id="c">
            <feMorphology radius=".81" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"/>
            <feOffset dx="-5" dy="3" in="shadowSpreadOuter1" result="shadowOffsetOuter1"/>
            <feComposite in="shadowOffsetOuter1" in2="SourceAlpha" operator="out" result="shadowOffsetOuter1"/>
            <feColorMatrix values="0 0 0 0 0.639215686 0 0 0 0 0.694117647 0 0 0 0 0.749019608 0 0 0 0.3 0"
                           in="shadowOffsetOuter1"/>
          </filter>
          <path
              d="M161.51 89.858h15.333v17.534H161.51a6.568 6.568 0 0 1-6.567-6.568v-4.398a6.568 6.568 0 0 1 6.567-6.568z"
              id="f"/>
          <filter x="-13.7%" y="-5.7%" width="118.3%" height="117.1%" filterUnits="objectBoundingBox" id="e">
            <feOffset dx="-2" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feComposite in="shadowOffsetOuter1" in2="SourceAlpha" operator="out" result="shadowOffsetOuter1"/>
            <feColorMatrix values="0 0 0 0 0.639215686 0 0 0 0 0.694117647 0 0 0 0 0.749019608 0 0 0 0.3 0"
                           in="shadowOffsetOuter1"/>
          </filter>
          <path
              d="M183.958 89.858h15.332v17.534h-15.332a6.568 6.568 0 0 1-6.568-6.568v-4.398a6.568 6.568 0 0 1 6.568-6.568z"
              id="h"/>
          <filter x="-2.3%" y="-2.9%" width="109.1%" height="111.4%" filterUnits="objectBoundingBox" id="g">
            <feOffset dx="1" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feComposite in="shadowOffsetOuter1" in2="SourceAlpha" operator="out" result="shadowOffsetOuter1"/>
            <feColorMatrix values="0 0 0 0 0.639215686 0 0 0 0 0.694117647 0 0 0 0 0.749019608 0 0 0 0.3 0"
                           in="shadowOffsetOuter1"/>
          </filter>
          <filter x="-55.5%" y="-9.3%" width="177.5%" height="148%" filterUnits="objectBoundingBox" id="i">
            <feOffset dx="-1" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feColorMatrix values="0 0 0 0 0.639215686 0 0 0 0 0.694117647 0 0 0 0 0.749019608 0 0 0 0.3 0"
                           in="shadowOffsetOuter1" result="shadowMatrixOuter1"/>
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1"/>
              <feMergeNode in="SourceGraphic"/>
            </feMerge>
          </filter>
          <filter x="-55.5%" y="-9.3%" width="177.5%" height="148%" filterUnits="objectBoundingBox" id="j">
            <feOffset dx="-1" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feColorMatrix values="0 0 0 0 0.639215686 0 0 0 0 0.694117647 0 0 0 0 0.749019608 0 0 0 0.3 0"
                           in="shadowOffsetOuter1" result="shadowMatrixOuter1"/>
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1"/>
              <feMergeNode in="SourceGraphic"/>
            </feMerge>
          </filter>
          <path
              d="M8.865 17.248a1.754 1.754 0 0 1-.77-1.454 1.75 1.75 0 0 1 1.748-1.754 1.75 1.75 0 0 1 1.747 1.754c0 .605-.305 1.139-.77 1.454l.976 2.737a.615.615 0 0 1-.579.82H8.468a.615.615 0 0 1-.579-.82l.976-2.737z"
              id="k"/>
          <filter x="-12%" y="-7.4%" width="124.1%" height="114.8%" filterUnits="objectBoundingBox" id="l">
            <feOffset dx="-1" dy="1" in="SourceAlpha" result="shadowOffsetInner1"/>
            <feComposite in="shadowOffsetInner1" in2="SourceAlpha" operator="arithmetic" k2="-1" k3="1"
                         result="shadowInnerInner1"/>
            <feColorMatrix values="0 0 0 0 0.639215686 0 0 0 0 0.694117647 0 0 0 0 0.749019608 0 0 0 1 0"
                           in="shadowInnerInner1"/>
          </filter>
          <filter x="-50%" width="160%" height="160%" filterUnits="objectBoundingBox" id="m">
            <feOffset dx="-1" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feColorMatrix values="0 0 0 0 0.639215686 0 0 0 0 0.694117647 0 0 0 0 0.749019608 0 0 0 0.3 0"
                           in="shadowOffsetOuter1" result="shadowMatrixOuter1"/>
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1"/>
              <feMergeNode in="SourceGraphic"/>
            </feMerge>
          </filter>
          <filter x="-50%" width="160%" height="160%" filterUnits="objectBoundingBox" id="n">
            <feOffset dx="-1" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feColorMatrix values="0 0 0 0 0.639215686 0 0 0 0 0.694117647 0 0 0 0 0.749019608 0 0 0 0.3 0"
                           in="shadowOffsetOuter1" result="shadowMatrixOuter1"/>
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1"/>
              <feMergeNode in="SourceGraphic"/>
            </feMerge>
          </filter>
          <filter x="-50%" width="160%" height="160%" filterUnits="objectBoundingBox" id="o">
            <feOffset dx="-1" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feColorMatrix values="0 0 0 0 0.639215686 0 0 0 0 0.694117647 0 0 0 0 0.749019608 0 0 0 0.3 0"
                           in="shadowOffsetOuter1" result="shadowMatrixOuter1"/>
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1"/>
              <feMergeNode in="SourceGraphic"/>
            </feMerge>
          </filter>
          <filter x="-50%" width="160%" height="160%" filterUnits="objectBoundingBox" id="p">
            <feOffset dx="-1" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feColorMatrix values="0 0 0 0 0.639215686 0 0 0 0 0.694117647 0 0 0 0 0.749019608 0 0 0 0.3 0"
                           in="shadowOffsetOuter1" result="shadowMatrixOuter1"/>
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1"/>
              <feMergeNode in="SourceGraphic"/>
            </feMerge>
          </filter>
          <filter x="-50%" width="160%" height="160%" filterUnits="objectBoundingBox" id="q">
            <feOffset dx="-1" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feColorMatrix values="0 0 0 0 0.639215686 0 0 0 0 0.694117647 0 0 0 0 0.749019608 0 0 0 0.3 0"
                           in="shadowOffsetOuter1" result="shadowMatrixOuter1"/>
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1"/>
              <feMergeNode in="SourceGraphic"/>
            </feMerge>
          </filter>
          <filter x="-50%" width="160%" height="160%" filterUnits="objectBoundingBox" id="r">
            <feOffset dx="-1" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feColorMatrix values="0 0 0 0 0.639215686 0 0 0 0 0.694117647 0 0 0 0 0.749019608 0 0 0 0.3 0"
                           in="shadowOffsetOuter1" result="shadowMatrixOuter1"/>
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1"/>
              <feMergeNode in="SourceGraphic"/>
            </feMerge>
          </filter>
          <filter x="-50%" width="160%" height="160%" filterUnits="objectBoundingBox" id="s">
            <feOffset dx="-1" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feColorMatrix values="0 0 0 0 0.639215686 0 0 0 0 0.694117647 0 0 0 0 0.749019608 0 0 0 0.3 0"
                           in="shadowOffsetOuter1" result="shadowMatrixOuter1"/>
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1"/>
              <feMergeNode in="SourceGraphic"/>
            </feMerge>
          </filter>
          <filter x="-50%" width="160%" height="160%" filterUnits="objectBoundingBox" id="t">
            <feOffset dx="-1" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feColorMatrix values="0 0 0 0 0.639215686 0 0 0 0 0.694117647 0 0 0 0 0.749019608 0 0 0 0.3 0"
                           in="shadowOffsetOuter1" result="shadowMatrixOuter1"/>
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1"/>
              <feMergeNode in="SourceGraphic"/>
            </feMerge>
          </filter>
          <filter x="-50%" width="160%" height="160%" filterUnits="objectBoundingBox" id="u">
            <feOffset dx="-1" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feColorMatrix values="0 0 0 0 0.639215686 0 0 0 0 0.694117647 0 0 0 0 0.749019608 0 0 0 0.3 0"
                           in="shadowOffsetOuter1" result="shadowMatrixOuter1"/>
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1"/>
              <feMergeNode in="SourceGraphic"/>
            </feMerge>
          </filter>
          <filter x="-50%" width="160%" height="160%" filterUnits="objectBoundingBox" id="v">
            <feOffset dx="-1" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feColorMatrix values="0 0 0 0 0.639215686 0 0 0 0 0.694117647 0 0 0 0 0.749019608 0 0 0 0.3 0"
                           in="shadowOffsetOuter1" result="shadowMatrixOuter1"/>
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1"/>
              <feMergeNode in="SourceGraphic"/>
            </feMerge>
          </filter>
          <filter x="-44.4%" y="-20%" width="166.7%" height="160%" filterUnits="objectBoundingBox" id="w">
            <feOffset dx="-2" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feColorMatrix values="0 0 0 0 0.639215686 0 0 0 0 0.694117647 0 0 0 0 0.749019608 0 0 0 0.3 0"
                           in="shadowOffsetOuter1" result="shadowMatrixOuter1"/>
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1"/>
              <feMergeNode in="SourceGraphic"/>
            </feMerge>
          </filter>
          <filter x="-44.4%" y="-20%" width="166.7%" height="160%" filterUnits="objectBoundingBox" id="x">
            <feOffset dx="-2" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feColorMatrix values="0 0 0 0 0.639215686 0 0 0 0 0.694117647 0 0 0 0 0.749019608 0 0 0 0.3 0"
                           in="shadowOffsetOuter1" result="shadowMatrixOuter1"/>
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1"/>
              <feMergeNode in="SourceGraphic"/>
            </feMerge>
          </filter>
          <filter x="-44.4%" y="-20%" width="166.7%" height="160%" filterUnits="objectBoundingBox" id="y">
            <feOffset dx="-2" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feColorMatrix values="0 0 0 0 0.639215686 0 0 0 0 0.694117647 0 0 0 0 0.749019608 0 0 0 0.3 0"
                           in="shadowOffsetOuter1" result="shadowMatrixOuter1"/>
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1"/>
              <feMergeNode in="SourceGraphic"/>
            </feMerge>
          </filter>
          <filter x="-44.4%" y="-20%" width="166.7%" height="160%" filterUnits="objectBoundingBox" id="z">
            <feOffset dx="-2" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feColorMatrix values="0 0 0 0 0.639215686 0 0 0 0 0.694117647 0 0 0 0 0.749019608 0 0 0 0.3 0"
                           in="shadowOffsetOuter1" result="shadowMatrixOuter1"/>
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1"/>
              <feMergeNode in="SourceGraphic"/>
            </feMerge>
          </filter>
          <filter x="-44.4%" y="-20%" width="166.7%" height="160%" filterUnits="objectBoundingBox" id="A">
            <feOffset dx="-2" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feColorMatrix values="0 0 0 0 0.639215686 0 0 0 0 0.694117647 0 0 0 0 0.749019608 0 0 0 0.3 0"
                           in="shadowOffsetOuter1" result="shadowMatrixOuter1"/>
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1"/>
              <feMergeNode in="SourceGraphic"/>
            </feMerge>
          </filter>
          <filter x="-44.4%" y="-20%" width="166.7%" height="160%" filterUnits="objectBoundingBox" id="B">
            <feOffset dx="-2" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feColorMatrix values="0 0 0 0 0.639215686 0 0 0 0 0.694117647 0 0 0 0 0.749019608 0 0 0 0.3 0"
                           in="shadowOffsetOuter1" result="shadowMatrixOuter1"/>
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1"/>
              <feMergeNode in="SourceGraphic"/>
            </feMerge>
          </filter>
          <filter x="-44.4%" y="-20%" width="166.7%" height="160%" filterUnits="objectBoundingBox" id="C">
            <feOffset dx="-2" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feColorMatrix values="0 0 0 0 0.639215686 0 0 0 0 0.694117647 0 0 0 0 0.749019608 0 0 0 0.3 0"
                           in="shadowOffsetOuter1" result="shadowMatrixOuter1"/>
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1"/>
              <feMergeNode in="SourceGraphic"/>
            </feMerge>
          </filter>
          <path id="E" d="M79.083 68.794h60.833v24.352H79.083z"/>
          <filter x="-2.5%" y="-2.1%" width="103.3%" height="108.2%" filterUnits="objectBoundingBox" id="D">
            <feOffset dx="-1" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feComposite in="shadowOffsetOuter1" in2="SourceAlpha" operator="out" result="shadowOffsetOuter1"/>
            <feColorMatrix values="0 0 0 0 0.639215686 0 0 0 0 0.694117647 0 0 0 0 0.749019608 0 0 0 0.3 0"
                           in="shadowOffsetOuter1"/>
          </filter>
          <path id="F" d="M119.233 71.235h18.25v19.507h-18.25z"/>
          <path d="M6.692.215h8.762v30.683H6.692A6.568 6.568 0 0 1 .124 24.33V6.782A6.568 6.568 0 0 1 6.692.215z"
                id="H"/>
          <filter x="-29.4%" y="-4.9%" width="139.1%" height="116.3%" filterUnits="objectBoundingBox" id="G">
            <feOffset dx="-3" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feComposite in="shadowOffsetOuter1" in2="SourceAlpha" operator="out" result="shadowOffsetOuter1"/>
            <feColorMatrix values="0 0 0 0 0.866666667 0 0 0 0 0.890196078 0 0 0 0 0.909803922 0 0 0 1 0"
                           in="shadowOffsetOuter1"/>
          </filter>
          <filter x="-50%" width="160%" height="160%" filterUnits="objectBoundingBox" id="I">
            <feOffset dx="-1" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feColorMatrix values="0 0 0 0 0.639215686 0 0 0 0 0.694117647 0 0 0 0 0.749019608 0 0 0 0.3 0"
                           in="shadowOffsetOuter1" result="shadowMatrixOuter1"/>
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1"/>
              <feMergeNode in="SourceGraphic"/>
            </feMerge>
          </filter>
          <filter x="-50%" width="160%" height="160%" filterUnits="objectBoundingBox" id="J">
            <feOffset dx="-1" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feColorMatrix values="0 0 0 0 0.639215686 0 0 0 0 0.694117647 0 0 0 0 0.749019608 0 0 0 0.3 0"
                           in="shadowOffsetOuter1" result="shadowMatrixOuter1"/>
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1"/>
              <feMergeNode in="SourceGraphic"/>
            </feMerge>
          </filter>
          <path
              d="M42.83 11.876v7.36c0 .69-.493 1.3-1.091 1.36l-24.095 2.083V8.433l24.095 2.084c.602.06 1.09.668 1.09 1.359z"
              id="K"/>
          <filter x="-50%" width="160%" height="160%" filterUnits="objectBoundingBox" id="L">
            <feOffset dx="-1" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feColorMatrix values="0 0 0 0 0.639215686 0 0 0 0 0.694117647 0 0 0 0 0.749019608 0 0 0 0.3 0"
                           in="shadowOffsetOuter1" result="shadowMatrixOuter1"/>
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1"/>
              <feMergeNode in="SourceGraphic"/>
            </feMerge>
          </filter>
          <filter x="-50%" width="160%" height="160%" filterUnits="objectBoundingBox" id="M">
            <feOffset dx="-1" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feColorMatrix values="0 0 0 0 0.639215686 0 0 0 0 0.694117647 0 0 0 0 0.749019608 0 0 0 0.3 0"
                           in="shadowOffsetOuter1" result="shadowMatrixOuter1"/>
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1"/>
              <feMergeNode in="SourceGraphic"/>
            </feMerge>
          </filter>
          <rect id="O" x="12.23" y="6.79" width="7.117" height="17.533" rx="1.095"/>
          <filter x="-42.1%" y="-5.7%" width="156.2%" height="117.1%" filterUnits="objectBoundingBox" id="N">
            <feOffset dx="-2" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feComposite in="shadowOffsetOuter1" in2="SourceAlpha" operator="out" result="shadowOffsetOuter1"/>
            <feColorMatrix values="0 0 0 0 0.639215686 0 0 0 0 0.694117647 0 0 0 0 0.749019608 0 0 0 0.3 0"
                           in="shadowOffsetOuter1"/>
          </filter>
          <path d="M6.692.215h8.762v30.683H6.692A6.568 6.568 0 0 1 .124 24.33V6.782A6.568 6.568 0 0 1 6.692.215z"
                id="Q"/>
          <filter x="-29.4%" y="-4.9%" width="139.1%" height="116.3%" filterUnits="objectBoundingBox" id="P">
            <feOffset dx="-3" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feComposite in="shadowOffsetOuter1" in2="SourceAlpha" operator="out" result="shadowOffsetOuter1"/>
            <feColorMatrix values="0 0 0 0 0.866666667 0 0 0 0 0.890196078 0 0 0 0 0.909803922 0 0 0 1 0"
                           in="shadowOffsetOuter1"/>
          </filter>
          <filter x="-50%" width="160%" height="160%" filterUnits="objectBoundingBox" id="R">
            <feOffset dx="-1" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feColorMatrix values="0 0 0 0 0.639215686 0 0 0 0 0.694117647 0 0 0 0 0.749019608 0 0 0 0.3 0"
                           in="shadowOffsetOuter1" result="shadowMatrixOuter1"/>
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1"/>
              <feMergeNode in="SourceGraphic"/>
            </feMerge>
          </filter>
          <filter x="-50%" width="160%" height="160%" filterUnits="objectBoundingBox" id="S">
            <feOffset dx="-1" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feColorMatrix values="0 0 0 0 0.639215686 0 0 0 0 0.694117647 0 0 0 0 0.749019608 0 0 0 0.3 0"
                           in="shadowOffsetOuter1" result="shadowMatrixOuter1"/>
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1"/>
              <feMergeNode in="SourceGraphic"/>
            </feMerge>
          </filter>
          <path
              d="M42.83 11.876v7.36c0 .69-.493 1.3-1.091 1.36l-24.095 2.083V8.433l24.095 2.084c.602.06 1.09.668 1.09 1.359z"
              id="T"/>
          <filter x="-50%" width="160%" height="160%" filterUnits="objectBoundingBox" id="U">
            <feOffset dx="-1" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feColorMatrix values="0 0 0 0 0.639215686 0 0 0 0 0.694117647 0 0 0 0 0.749019608 0 0 0 0.3 0"
                           in="shadowOffsetOuter1" result="shadowMatrixOuter1"/>
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1"/>
              <feMergeNode in="SourceGraphic"/>
            </feMerge>
          </filter>
          <filter x="-50%" width="160%" height="160%" filterUnits="objectBoundingBox" id="V">
            <feOffset dx="-1" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feColorMatrix values="0 0 0 0 0.639215686 0 0 0 0 0.694117647 0 0 0 0 0.749019608 0 0 0 0.3 0"
                           in="shadowOffsetOuter1" result="shadowMatrixOuter1"/>
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1"/>
              <feMergeNode in="SourceGraphic"/>
            </feMerge>
          </filter>
          <rect id="X" x="12.23" y="6.79" width="7.117" height="17.533" rx="1.095"/>
          <filter x="-42.1%" y="-5.7%" width="156.2%" height="117.1%" filterUnits="objectBoundingBox" id="W">
            <feOffset dx="-2" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feComposite in="shadowOffsetOuter1" in2="SourceAlpha" operator="out" result="shadowOffsetOuter1"/>
            <feColorMatrix values="0 0 0 0 0.639215686 0 0 0 0 0.694117647 0 0 0 0 0.749019608 0 0 0 0.3 0"
                           in="shadowOffsetOuter1"/>
          </filter>
          <path
              d="M24.598 18.916c-1.887-.225-4.41-.546-5.998-.826-2.828-.498-8.375-1.863-8.375-1.863l-.793 4.498s5.701.552 8.518 1.048c1.365.241 3.377.703 5.086 1.114-.964 1.487-2.121 3.236-2.943 4.366-1.688 2.324-5.346 6.71-5.346 6.71l3.695 2.685s2.995-4.882 4.677-7.196c.84-1.158 2.196-2.832 3.338-4.213.981 1.507 2.148 3.335 2.864 4.575 1.436 2.487 3.956 7.613 3.956 7.613l3.955-2.283s-3.241-4.722-4.672-7.2c-.752-1.303-1.795-3.358-2.613-5.01 1.788-.483 4.046-1.073 5.526-1.388 2.81-.597 8.463-1.409 8.463-1.409l-.95-4.468s-5.492 1.624-8.29 2.219c-1.553.33-4.001.71-5.868.98-.105-1.888-.225-4.382-.225-5.972 0-2.872.381-8.57.381-8.57h-4.567s.446 5.71.446 8.57c0 1.609-.142 4.128-.265 6.02z"
              id="Y"/>
          <filter x="-14.6%" y="-6.2%" width="120.9%" height="121.4%" filterUnits="objectBoundingBox" id="Z">
            <feMorphology radius=".547" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"/>
            <feOffset dx="-3" dy="3" in="shadowSpreadOuter1" result="shadowOffsetOuter1"/>
            <feComposite in="shadowOffsetOuter1" in2="SourceAlpha" operator="out" result="shadowOffsetOuter1"/>
            <feColorMatrix values="0 0 0 0 0.639215686 0 0 0 0 0.694117647 0 0 0 0 0.749019608 0 0 0 0.3 0"
                           in="shadowOffsetOuter1"/>
          </filter>
          <ellipse id="ab" cx="26.803" cy="22.671" rx="4.38" ry="4.383"/>
          <filter x="-34.2%" y="-11.4%" width="145.7%" height="134.2%" filterUnits="objectBoundingBox" id="aa">
            <feOffset dx="-2" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feColorMatrix values="0 0 0 0 0.596078431 0 0 0 0 0.647058824 0 0 0 0 0.701960784 0 0 0 1 0"
                           in="shadowOffsetOuter1"/>
          </filter>
        </defs>
        <g fill="none" fill-rule="evenodd">
          <g transform="translate(7 1)">
            <use fill="#000" filter="url(#a)" xlink:href="#b"/>
            <use stroke="#A3B1BF" stroke-width="1.62" fill="#F0F2F5" xlink:href="#b"/>
            <use fill="#000" filter="url(#c)" xlink:href="#d"/>
            <use stroke="#A3B1BF" stroke-width="1.62" fill-opacity=".15" fill="#A3B1BF" xlink:href="#d"/>
            <use fill="#000" filter="url(#e)" xlink:href="#f"/>
            <use fill="#F0F2F5" xlink:href="#f"/>
            <path stroke="#A3B1BF" stroke-width="1.62"
                  d="M176.032 106.582V90.668H161.51a5.758 5.758 0 0 0-5.757 5.758v4.398a5.758 5.758 0 0 0 5.757 5.758h14.523z"/>
            <g transform="matrix(-1 0 0 1 376.68 0)">
              <use fill="#000" filter="url(#g)" xlink:href="#h"/>
              <use fill="#F0F2F5" xlink:href="#h"/>
              <path stroke="#A3B1BF" stroke-width="1.62"
                    d="M198.48 106.582V90.668h-14.522a5.758 5.758 0 0 0-5.758 5.758v4.398a5.758 5.758 0 0 0 5.758 5.758h14.522z"/>
            </g>
            <path
                d="M169.538 109.199h9.983v-2.882c.136.343-4.552 0-7.101 0l1.733-6.073c-2.547 0-4.615 2.05-4.615 4.579v4.376zm11.867-2.435l-.07-.935c-2.842.49-5.457 0-9.247 0l2.065-7.813c-3.787 0-6.861 3.048-6.861 6.807v1.941h-.422c-1.111 0-2.012.9-2.012 2.012v6.764a8.045 8.045 0 0 0 8.046 8.046h3.376a8.045 8.045 0 0 0 8.045-8.046v-6.764c0-1.111-.9-2.012-2.011-2.012h-.91z"
                fill="#D4DBE2" fill-rule="nonzero"/>
            <g filter="url(#i)" transform="translate(160.42 95.473)">
              <ellipse stroke="#D9D9D9" stroke-width="1.095" fill="#F5F5F5" cx="2.19" cy="2.74" rx="2.19" ry="2.192"/>
              <path fill="#D9D9D9" d="M3.781 4.39L1.171.497.224 2.09.101 4.39z"/>
            </g>
            <g filter="url(#j)" transform="translate(189.26 95.473)">
              <ellipse stroke="#D9D9D9" stroke-width="1.095" fill="#F5F5F5" cx="2.19" cy="2.74" rx="2.19" ry="2.192"/>
              <path fill="#D9D9D9" d="M3.781 4.39L1.171.497.224 2.09.101 4.39z"/>
            </g>
            <path
                d="M169.117 106.147v-1.598c0-3.411 2.216-6.3 5.272-7.274v3.576a4.378 4.378 0 0 0-1.866 3.595v1.7h8.643v-1.7a4.38 4.38 0 0 0-1.262-3.085v-3.698a7.633 7.633 0 0 1 4.3 6.886v1.599a2.011 2.011 0 0 1 1.946 2.01v6.972a8.045 8.045 0 0 1-8.045 8.046h-3.376a8.045 8.045 0 0 1-8.046-8.046v-6.972c0-1.11.9-2.011 2.012-2.011h.422z"
                stroke="#A3B1BF" stroke-width="1.62" fill="#F0F2F5"/>
            <g transform="translate(166.683 96.799)">
              <use fill-opacity=".3" fill="#A3B1BF" xlink:href="#k"/>
              <use fill="#000" filter="url(#l)" xlink:href="#k"/>
            </g>
            <g filter="url(#m)" transform="translate(64 75)">
              <ellipse stroke="#A3B1BF" stroke-width="1.095" fill="#F5F5F5" cx="2.19" cy="2.74" rx="2.19" ry="2.192"/>
              <path fill="#A3B1BF" d="M3.781 4.39L1.171.497.224 2.09.101 4.39z"/>
            </g>
            <g filter="url(#n)" transform="translate(152 75)">
              <ellipse stroke="#A3B1BF" stroke-width="1.095" fill="#F5F5F5" cx="2.19" cy="2.74" rx="2.19" ry="2.192"/>
              <path fill="#A3B1BF" d="M3.781 4.39L1.171.497.224 2.09.101 4.39z"/>
            </g>
            <g filter="url(#o)" transform="translate(57 120)">
              <ellipse stroke="#A3B1BF" stroke-width="1.095" fill="#F5F5F5" cx="2.19" cy="2.74" rx="2.19" ry="2.192"/>
              <path fill="#A3B1BF" d="M3.781 4.39L1.171.497.224 2.09.101 4.39z"/>
            </g>
            <g filter="url(#p)" transform="translate(57 137)">
              <ellipse stroke="#A3B1BF" stroke-width="1.095" fill="#F5F5F5" cx="2.19" cy="2.74" rx="2.19" ry="2.192"/>
              <path fill="#A3B1BF" d="M3.781 4.39L1.171.497.224 2.09.101 4.39z"/>
            </g>
            <g filter="url(#q)" transform="translate(64 186)">
              <ellipse stroke="#A3B1BF" stroke-width="1.095" fill="#F5F5F5" cx="2.19" cy="2.74" rx="2.19" ry="2.192"/>
              <path fill="#A3B1BF" d="M3.781 4.39L1.171.497.224 2.09.101 4.39z"/>
            </g>
            <g filter="url(#r)" transform="translate(93 191)">
              <ellipse stroke="#A3B1BF" stroke-width="1.095" fill="#F5F5F5" cx="2.19" cy="2.74" rx="2.19" ry="2.192"/>
              <path fill="#A3B1BF" d="M3.781 4.39L1.171.497.224 2.09.101 4.39z"/>
            </g>
            <g filter="url(#s)" transform="translate(122 191)">
              <ellipse stroke="#A3B1BF" stroke-width="1.095" fill="#F5F5F5" cx="2.19" cy="2.74" rx="2.19" ry="2.192"/>
              <path fill="#A3B1BF" d="M3.781 4.39L1.171.497.224 2.09.101 4.39z"/>
            </g>
            <g filter="url(#t)" transform="translate(152 186)">
              <ellipse stroke="#A3B1BF" stroke-width="1.095" fill="#F5F5F5" cx="2.19" cy="2.74" rx="2.19" ry="2.192"/>
              <path fill="#A3B1BF" d="M3.781 4.39L1.171.497.224 2.09.101 4.39z"/>
            </g>
            <g filter="url(#u)" transform="translate(160.42 95.473)">
              <ellipse stroke="#A3B1BF" stroke-width="1.095" fill="#F5F5F5" cx="2.19" cy="2.74" rx="2.19" ry="2.192"/>
              <path fill="#A3B1BF" d="M3.781 4.39L1.171.497.224 2.09.101 4.39z"/>
            </g>
            <g filter="url(#v)" transform="translate(189.26 95.473)">
              <ellipse stroke="#A3B1BF" stroke-width="1.095" fill="#F7FAFC" cx="2.19" cy="2.74" rx="2.19" ry="2.192"/>
              <path fill="#A3B1BF" d="M3.781 4.39L1.171.497.224 2.09.101 4.39z"/>
            </g>
            <g filter="url(#w)" transform="translate(25 38)">
              <ellipse stroke="#A3B1BF" stroke-width="1.642" fill="#F7FAFC" cx="4.38" cy="4.931" rx="4.38" ry="4.383"/>
              <path fill="#A3B1BF" d="M4.004 9.133l3.558-.9L2.343.445.446 3.63.79 7.806z"/>
            </g>
            <g filter="url(#x)" transform="translate(25 218)">
              <ellipse stroke="#A3B1BF" stroke-width="1.642" fill="#F7FAFC" cx="4.38" cy="4.931" rx="4.38" ry="4.383"/>
              <path fill="#A3B1BF" d="M4.004 9.133l3.558-.9L2.343.445.446 3.63.79 7.806z"/>
            </g>
            <g filter="url(#y)" transform="translate(106 237)">
              <ellipse stroke="#A3B1BF" stroke-width="1.642" fill="#F7FAFC" cx="4.38" cy="4.931" rx="4.38" ry="4.383"/>
              <path fill="#A3B1BF" d="M4.004 9.133l3.558-.9L2.343.445.446 3.63.79 7.806z"/>
            </g>
            <g filter="url(#z)" transform="translate(185 219)">
              <ellipse stroke="#A3B1BF" stroke-width="1.642" fill="#F7FAFC" cx="4.38" cy="4.931" rx="4.38" ry="4.383"/>
              <path fill="#A3B1BF" d="M4.004 9.133l3.558-.9L2.343.445.446 3.63.79 7.806z"/>
            </g>
            <g filter="url(#A)" transform="translate(14.42 129.699)">
              <ellipse stroke="#A3B1BF" stroke-width="1.642" fill="#F7FAFC" cx="4.38" cy="4.931" rx="4.38" ry="4.383"/>
              <path fill="#A3B1BF" d="M4.004 9.133l3.558-.9L2.343.445.446 3.63.79 7.806z"/>
            </g>
            <g filter="url(#B)" transform="translate(105.444 20.716)">
              <ellipse stroke="#A3B1BF" stroke-width="1.642" fill="#F7FAFC" cx="4.38" cy="4.931" rx="4.38" ry="4.383"/>
              <path fill="#A3B1BF" d="M4.004 9.133l3.558-.9L2.343.445.446 3.63.79 7.806z"/>
            </g>
            <g filter="url(#C)" transform="translate(185.055 37.806)">
              <ellipse stroke="#A3B1BF" stroke-width="1.642" fill="#F5F5F5" cx="4.38" cy="4.931" rx="4.38" ry="4.383"/>
              <path fill="#A3B1BF" d="M4.004 9.133l3.558-.9L2.343.445.446 3.63.79 7.806z"/>
            </g>
            <use fill="#000" filter="url(#D)" xlink:href="#E"/>
            <use fill="#F0F2F5" xlink:href="#E"/>
            <path stroke="#A3B1BF" stroke-width="2.43" d="M80.298 70.009h58.403v21.922H80.298z"/>
            <use fill-opacity=".3" fill="#A3B1BF" xlink:href="#F"/>
            <rect fill="#A3B1BF" x="114.367" y="77.317" width="1.825" height="8.117" rx=".912"/>
            <rect fill="#A3B1BF" x="118.017" y="72.65" width="2.433" height="16.235" rx="1.217"/>
            <g>
              <g transform="translate(25 88)">
                <use fill="#000" filter="url(#G)" xlink:href="#H"/>
                <use fill="#EAEFF3" xlink:href="#H"/>
                <path stroke="#A3B1BF" stroke-width="1.62"
                      d="M14.644 30.088V1.025H6.692A5.758 5.758 0 0 0 .934 6.782V24.33a5.758 5.758 0 0 0 5.758 5.758h7.952z"/>
              </g>
              <g filter="url(#I)" transform="translate(31.207 94.79)">
                <ellipse stroke="#A3B1BF" stroke-width="1.095" fill="#F7FAFC" cx="2.19" cy="2.74" rx="2.19" ry="2.192"/>
                <path fill="#A3B1BF" d="M3.781 4.39L1.171.497.224 2.09.101 4.39z"/>
              </g>
              <g filter="url(#J)" transform="translate(31.543 107.182)">
                <ellipse stroke="#A3B1BF" stroke-width="1.095" fill="#F7FAFC" cx="2.19" cy="2.74" rx="2.19" ry="2.192"/>
                <path fill="#A3B1BF" d="M3.781 4.39L1.171.497.224 2.09.101 4.39z"/>
              </g>
              <g transform="translate(25 88)">
                <use fill="#F0F2F5" xlink:href="#K"/>
                <path stroke="#A3B1BF" stroke-width="1.62"
                      d="M18.454 9.316v12.48l23.203-2.006c.165-.017.362-.262.362-.553v-7.361c0-.294-.195-.537-.35-.552L18.454 9.316z"/>
              </g>
              <g filter="url(#L)" transform="translate(48 101)">
                <ellipse stroke="#A3B1BF" stroke-width="1.095" fill="#F7FAFC" cx="2.19" cy="2.74" rx="2.19" ry="2.192"/>
                <path fill="#A3B1BF" d="M3.781 4.39L1.171.497.224 2.09.101 4.39z"/>
              </g>
              <g filter="url(#M)" transform="translate(58 101)">
                <ellipse stroke="#A3B1BF" stroke-width="1.095" fill="#F7FAFC" cx="2.19" cy="2.74" rx="2.19" ry="2.192"/>
                <path fill="#A3B1BF" d="M3.781 4.39L1.171.497.224 2.09.101 4.39z"/>
              </g>
              <g transform="translate(25 88)">
                <use fill="#000" filter="url(#N)" xlink:href="#O"/>
                <use fill="#EAEFF3" xlink:href="#O"/>
                <rect stroke="#A3B1BF" stroke-width="1.62" x="13.04" y="7.6" width="5.497" height="15.913" rx="1.095"/>
              </g>
              <path d="M38.599 103.556h4.38" stroke="#A3B1BF" stroke-width="2.189" stroke-linecap="square"/>
            </g>
            <g>
              <g transform="translate(25 145)">
                <use fill="#000" filter="url(#P)" xlink:href="#Q"/>
                <use fill="#EAEFF3" xlink:href="#Q"/>
                <path stroke="#A3B1BF" stroke-width="1.62"
                      d="M14.644 30.088V1.025H6.692A5.758 5.758 0 0 0 .934 6.782V24.33a5.758 5.758 0 0 0 5.758 5.758h7.952z"/>
              </g>
              <g filter="url(#R)" transform="translate(31.207 151.79)">
                <ellipse stroke="#A3B1BF" stroke-width="1.095" fill="#F7FAFC" cx="2.19" cy="2.74" rx="2.19" ry="2.192"/>
                <path fill="#A3B1BF" d="M3.781 4.39L1.171.497.224 2.09.101 4.39z"/>
              </g>
              <g filter="url(#S)" transform="translate(31.543 164.182)">
                <ellipse stroke="#A3B1BF" stroke-width="1.095" fill="#F7FAFC" cx="2.19" cy="2.74" rx="2.19" ry="2.192"/>
                <path fill="#A3B1BF" d="M3.781 4.39L1.171.497.224 2.09.101 4.39z"/>
              </g>
              <g transform="translate(25 145)">
                <use fill="#F0F2F5" xlink:href="#T"/>
                <path stroke="#A3B1BF" stroke-width="1.62"
                      d="M18.454 9.316v12.48l23.203-2.006c.165-.017.362-.262.362-.553v-7.361c0-.294-.195-.537-.35-.552L18.454 9.316z"/>
              </g>
              <g filter="url(#U)" transform="translate(48 158)">
                <ellipse stroke="#A3B1BF" stroke-width="1.095" fill="#F7FAFC" cx="2.19" cy="2.74" rx="2.19" ry="2.192"/>
                <path fill="#A3B1BF" d="M3.781 4.39L1.171.497.224 2.09.101 4.39z"/>
              </g>
              <g filter="url(#V)" transform="translate(58 158)">
                <ellipse stroke="#A3B1BF" stroke-width="1.095" fill="#F7FAFC" cx="2.19" cy="2.74" rx="2.19" ry="2.192"/>
                <path fill="#A3B1BF" d="M3.781 4.39L1.171.497.224 2.09.101 4.39z"/>
              </g>
              <g transform="translate(25 145)">
                <use fill="#000" filter="url(#W)" xlink:href="#X"/>
                <use fill="#EAEFF3" xlink:href="#X"/>
                <rect stroke="#A3B1BF" stroke-width="1.62" x="13.04" y="7.6" width="5.497" height="15.913" rx="1.095"/>
              </g>
              <path d="M38.599 160.556h4.38" stroke="#A3B1BF" stroke-width="2.189" stroke-linecap="square"/>
            </g>
          </g>
          <path
              d="M164.669 178.731c-12.398 0-22.448-10.058-22.448-22.465 0-12.406 10.05-22.464 22.448-22.464 12.397 0 22.447 10.058 22.447 22.464 0 12.407-10.05 22.465-22.447 22.465zm0-6.575c8.769 0 15.877-7.114 15.877-15.89 0-8.775-7.108-15.89-15.877-15.89-8.77 0-15.878 7.115-15.878 15.89 0 8.776 7.109 15.89 15.878 15.89z"
              fill-opacity=".3" fill="#A3B1BF"/>
          <g transform="translate(142.185 132.5)">
            <use fill="#000" filter="url(#Z)" xlink:href="#Y"/>
            <use stroke="#A3B1BF" stroke-width="1.095" fill="#A3B1BF" xlink:href="#Y"/>
          </g>
          <path
              d="M163.11 133.506a22.515 22.515 0 0 1 4.803-.516c12.398 0 22.448 10.058 22.448 22.465 0 10.862-7.704 19.924-17.943 22.012l-2.403-6.26c7.776-1.03 13.776-7.69 13.776-15.752 0-8.776-7.109-15.89-15.878-15.89a15.796 15.796 0 0 0-8.976 2.781l-1.083-2.82 5.255-6.02z"
              fill="#98A5B3"/>
          <path
              d="M168.988 177.635c-12.398 0-22.448-10.058-22.448-22.464 0-12.407 10.05-22.465 22.448-22.465 12.397 0 22.447 10.058 22.447 22.465 0 12.406-10.05 22.464-22.447 22.464zm0-6.575c8.769 0 15.877-7.114 15.877-15.89 0-8.775-7.108-15.889-15.877-15.889-8.769 0-15.878 7.114-15.878 15.89 0 8.775 7.109 15.89 15.878 15.89z"
              fill="#A3B1BF"/>
          <path d="M179.754 138.974s2.787 1.59 5.432 6.022c2.644 4.432 2.986 8.99 2.986 8.99" stroke="#F7FAFC"
                stroke-width="1.62" stroke-linecap="round"/>
          <g transform="translate(142.185 132.5)">
            <use fill="#000" filter="url(#aa)" xlink:href="#ab"/>
            <use fill="#A3B1BF" xlink:href="#ab"/>
          </g>
          <path d="M171.213 156.862a2.434 2.434 0 0 0-3.321-3.562s1.204.368 2.12 1.351c.918.984 1.201 2.21 1.201 2.21z"
                fill="#F7FAFC"/>
        </g>
      </svg>
    </div>
    <div class="ele-exception-content ele-text-left">
      <h1 class="ele-text">403</h1>
      <p class="ele-text-secondary">抱歉，你无权访问该页面</p>
      <div>
        <el-button type="primary" @click="$router.push('/')">返回首页</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Page403"
}
</script>

<style scoped>
.ele-exception {
  margin: 150px 0;
}

.ele-exception .ele-exception-img svg {
  max-width: 95%;
}

.ele-exception .ele-exception-img, .ele-exception .ele-exception-content {
  margin: 15px 30px;
  display: inline-block;
  vertical-align: middle;
}

.ele-exception .ele-exception-content > h1 {
  font-size: 72px;
  font-weight: 600;
  margin: 0 0 20px 0;
}

.ele-exception:not(.ele-exception-dark) .ele-exception-content > h1 {
  color: #515A6E;
}

.ele-exception .ele-exception-content > p {
  font-size: 20px;
  margin: 0 0 25px 0;
}

.ele-exception:not(.ele-exception-dark) .ele-exception-content > p {
  color: #808695;
}

/** 适配暗黑主题 */
.ele-exception-dark .ele-exception-img {
  opacity: .7;
}

/** 小屏幕样式 */
@media screen and (max-width: 768px) {
  .ele-exception {
    margin: 40px 0;
  }

  .ele-exception .ele-exception-img {
    margin: 0;
  }

  .ele-exception .ele-exception-img svg {
    max-height: 200px;
  }

  .ele-exception .ele-exception-content {
    text-align: center;
  }
}
</style>